/*!
 * Training app
 */
// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Variables
@import 'variables';

// Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';

@import 'node_modules/jquery-ui/themes/base/all.css';

@import 'node_modules/lity/dist/lity.css';

@import 'fontawesome-all';

@import 'custom';

@import 'mobile';
