// Add new sizes
$sizes: (
    20: 20%,
);

// Body
$text-color: #666666;
$body-color: #666666;

// Colors
$red: #e02b20;
$dark: #303030;
$black: #000000;
$primary: $red;

// Add new theme colors
$theme-colors: (
    "black":  $black
);

// Spacers
$spacer: 1.25rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( ( 0: 0, 5: ($spacer * .25), //5px
10: ($spacer * .5), //10px
15: ($spacer * .75), //15px
20: $spacer, //20px
25: ($spacer * 1.25), //25px
30: ($spacer * 1.5), //30px
40: ($spacer * 2), //40px
50: ($spacer * 2.5), //50px
60: ($spacer * 3), //60px
70: ($spacer * 3.5), //70px
80: ($spacer * 4), //80px
100: ($spacer * 5), //100px
130: ($spacer * 6.5), //130px
150: ($spacer * 7.5), //150px
200: ($spacer * 10) //200px
), $spacers);

// Forms
$input-border-radius: 0;
$input-btn-focus-width: .1rem;

// Navs
$nav-link-padding-y: .3rem;

// Navbar
$navbar-nav-link-padding-x: .5rem;
$navbar-brand-font-size: 1.2rem;

// Dropdowns
$dropdown-border-radius: 0;

// Alerts
$alert-border-radius: 0;

// Progress
$progress-border-radius: 0;
