/*
 * Training app mobile
 */

/* LG and down */
@include media-breakpoint-down(lg) {
  .detail-cta {
    h2 {
      font-size: 26px;
      line-height: 36px;
    }
  }
}

/* XL and up */
@include media-breakpoint-up(xl) {
  body {
    padding-top: 113px;
    &.light {
      padding-top: 85px;
    }
  }
  header .social-links {
    margin-right: 135px;
  }
  header .navbar-nav .nav-item > .nav-link {
    padding: 0.25rem 0.5rem;
  }
}

/* MD and up */
@include media-breakpoint-up(md) {
  footer .copy {
    text-align: right;
  }
  footer .social-links {
    text-align: left;
  }
}

/* MD and down */
@include media-breakpoint-down(md) {
  /*header .navbar-nav .nav-item {
    border-bottom: 1px solid $gray-800;
  }
  header .navbar-nav .nav-item > .nav-link, header .navbar-nav .nav-item:last-child > .nav-link {
    margin: 5px 0;
  }
  .navbar-dark .navbar-toggler {
    border-color: transparent;
  }
  header .topbar {
    margin-top: 20px;
  }*/
}

/* SM and down */
@include media-breakpoint-down(sm) {
  /*.topbar .social-links {
    margin: 20px 0;
  }
  .topbar .language {
    margin-top: 20px;
  }*/
}

/* XS and down */
@include media-breakpoint-down(xs) {
  /*section.hero {
    height: 380px;
  }
  section.hero h1 {
    font-size: 28px;
    line-height: 42px;
  }
  section.hero h3 {
    font-size: 20px;
    line-height: 26px;
  }
  section {
    padding: 50px 0;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .step {
    margin-bottom: 50px;
  }
  .search-box {
    padding: 20px;
    margin-bottom: -100px;
  }
  .search-box h2 {
    font-size: 26px;
    line-height: 38px;
  }
  .detail-content h2 {
    font-size: 26px;
    line-height: 36px;
  }*/
}