/*
 * Training app custom css
 */
body {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: $text-color;
  padding-top: 85px;
}

.btn, .form-control {
  border-radius: 0;
}

header .navbar-brand {
  //padding: 0;
  img {
    height: 60px;
  }
}

header .navbar-toggler:focus, header .navbar-toggler:hover {
  outline: none;
}

header .navbar-toggler {
  border: none;
  padding: 0.25rem 0;
}

header .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

header .topbar {
  margin: 0.25rem 1.25rem;
}

header .social-links {
  & > a {
    margin-right: 0.25rem;
    & i.fa-2x {
      font-size: 1.75em;
    }
  }
}

header .navbar a {
  color: $white;
}

header .topbar .language {
  margin: 0 8px;
}

header .topbar .language button {
  color: white;
}

header .btn {
  font-weight: 400;
}

header .topbar .text-link {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-top: 12px;
}

header .topbar .btn-sm {
  /*font-size: 0.875rem;
  line-height: 1.5;*/
  text-transform: uppercase;
}

header .navbar-nav .nav-item > .nav-link {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
  color: #e5e5e5 !important;
  padding: 0.5rem 1.25rem;
}

header .navbar-nav .nav-item > .nav-link:hover {
  color: $gray-400 !important;
}

/*header .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0 !important;
}*/

header .dropdown-menu {
  background: $black;
  //margin-top: 0;
  left: 0;
}

header .dropdown-item {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
  color: #e5e5e5 !important;
  padding: 0.75rem 1.5rem;
  &:hover, &:focus {
    background: $black;
    color: $gray-400 !important;
  }
}

header .dropdown-menu > li a:hover, header .dropdown-menu > li.show {
  background: black;
  color: $primary;
}

header .dropdown-menu .nav-link.dropdown {
  padding: 0;
}

.dropdown-menu .dropdown-toggle::after {
  right: 0;
  position: absolute;
  margin-top: 10px;
}

section.hero {
  background-image: url("/images/background/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 435px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
}

section.hero h1 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
}

section.hero h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 30px;
}

section.hero ::placeholder {
  color: black;
  font-size: 16px;
  line-height: 22px;
}

.view-training {
  section.hero h1 {
    background-color: rgba(227, 30, 26, 0.85); // $primary: #E31E24
    color: $white;
    padding: 0.25rem 0.5rem;
  }

  .dropdown .dropdown-item {
    padding: 0.25rem 0.75rem;
  }

  .dropdown .btn small {
    display: none;
  }
}

.ui-menu .ui-menu-item-wrapper {
  border-color: $dark;
  padding: 0;
}

.ui-menu .ui-menu-item-wrapper .label {
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: $black;
  color: white;
}

.ui-menu .ui-menu-item-wrapper .icon {
  background-color: $primary;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active .icon {
  background: $black;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active .label {
  color: white;
}

.ui-menu .ui-menu-item {
  border-bottom: 1px solid $black;
}

.ui-menu .ui-menu-item:last-child {
  border: 0;
}

.ui-widget.ui-widget-content {
  border-color: black;
  max-width: calc(100% - 30px);
}

.input-group .form-control {
  padding: 24px 12px;
}

.input-group .btn {
  padding: 15px;
}

.input-group > .input-group-prepend > .input-group-text {
  background-color: rgba(240, 240, 240, 0.8);
}

section {
  padding: 100px 0;
}

h2 {
  font-size: 34px;
  line-height: 46px;
  color: $black;
  margin-bottom: 20px;
}

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}

h4 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
}

.section-category {
  padding: 0 0 70px 0;
}

.section-category a {
  text-decoration: none;
}

.category {
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-bottom: 30px
}

.category .title {
  background-color: $black;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
}

.category .title .icon {
  display: flex;
  align-items: center;
  background-color: $primary;
  width: 70px;
  height: 100%;
}

.category .title .icon img {
  width: 40px;
  height: 40px;
}

.category .title div {
  padding: 5px 18px;
}

.category .title h4 {
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
}

.category .title h6 {
  color: white;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 0;
}

.step .number {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #FFFFFF;
  background: $primary;
  width: 120px;
  height: 120px;
  border: 10px solid $primary;
  border-radius: 60px;
  margin: 0 auto;
}

.step .number div {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step .title h3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
}

.step .title h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

.formations {
  background: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  height: calc(100% - 30px);
  cursor: pointer;
}

.formations a {
  text-decoration: none;
}

.formations .picture {
  height: 218px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}

.formations .date {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  background-color: $primary;
  padding: 10px 15px;
  height: fit-content;
}

.formations .infos {
  padding: 0 15px 15px;
  margin-bottom: 30px;
}

.formations .infos .cat {
  display: flex;
  margin-top: -22px;
  align-items: flex-end;
}

.formations .infos .cat .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 40px;
  height: 40px;

  img {
    width: 30px;
    height: 30px;
  }
}

.formations .infos .cat h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.formations .infos .name {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-top: 20px;
  height: 75px;
  margin-bottom: 45px;
}

.formations .infos .details {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 22px;
  color: #8E8E8E;
  border-top: 2px solid #C4C4C4;
  padding-top: 20px;
}

.formations .infos .details div {
  display: flex;
}

.formations .infos .details img {
  margin-left: 10px;
}

.btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

footer {
  color: white;
  font-size: 14px;
  line-height: 16px;
}

footer .infos {
  list-style: none;
  padding: 0;
}

footer .infos > li {
  padding-left: 0;
  line-height: 19px;
  margin-bottom: 10px;
}

footer .contact {
  list-style: none;
  padding: 0;
}

footer .contact > li {
  line-height: 19px;
  margin-bottom: 10px;
}

footer .contact h2 {
  font-size: 18px;
  display: inline-block;
  line-height: 24px;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 1px solid #A3A3A3;
}

footer a, footer a:hover {
  color: white;
}

footer .language {
  text-align: center;
}

footer .language a {
  font-size: 16px;
  line-height: 22px;
  border-right: 1px solid #B61C14;
  padding: 0 5px;
}

footer .language a:last-child {
  border: 0;
}

footer .copy, footer .social-links {
  & > a {
    margin-right: 0.25rem;
  }
}

.select-category {
  height: 60px;
  background-color: white;
  text-transform: none;
}

.select-category button {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  background-color: white;
  border: 0;
  width: 100%;
  padding: 17px 22px;
  text-align: left;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-category .dropdown-toggle::after {
  right: 20px;
  top: 28px;
  position: absolute;
}

.select-category .dropdown-menu {
  border-radius: 0;
  border-color: $dark;
  max-width: 100%;
  padding: 0;
}

.select-category .dropdown-item {
  border-bottom: 1px solid $black;
  padding: 0px;
  white-space: unset;
  display: flex;
  align-items: center;
}

.select-category .dropdown-item:last-child {
  border: 0;
}

.select-category .dropdown-item .icon {
  background-color: $primary;
  width: 48px;
  min-height: 48px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;

}

.select-category .dropdown-item .title {
  padding: 5px 10px;
  width: calc(100% - 48px);
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $black;
}

.select-category .dropdown-item:hover,
.select-category .dropdown-item:focus,
.select-category .dropdown-item:hover .icon,
.select-category .dropdown-item:focus .icon {
  background-color: $black;
}

.select-category .dropdown-item:hover .title,
.select-category .dropdown-item:focus .title {
  color: white;
}

.search-box {
  margin-bottom: -220px;
  background-color: $black;
  padding: 40px;
}

.search-box h2 {
  font-weight: normal;
  font-size: 34px;
  line-height: 46px;
  color: #FFFFFF;
  margin-bottom: 20px;
  text-align: left;
}

.detail-title {
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
}

.box {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
}

.box h3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: $black;
}

.box .dropdown button {
  font-size: 14px;
  line-height: 19px;
  text-transform: none;
}

.btn-white {
  background-color: white;
  border: 1px solid #8E8E8E;
}

.box .infos {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.box .infos .icon {
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 2px;
}

.box h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $black;
  margin-bottom: 10px;
}

.box input {
  font-size: 14px;
  line-height: 19px;
}

.box .btn-dark {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: none;
}

.detail-content h2 {
  font-size: 34px;
  line-height: 46px;
  color: $black;
  border-bottom: 3px solid $primary;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.detail-content div {
  margin-bottom: 20px;
}

.alert > p:last-child {
  margin-bottom: 0;
}

.private-container {
  .nav-pills .nav-link {
    border-radius: 0;
  }

  h2, h3, h4 {
    color: $black;
  }

  h4 {
    font-size: 21px;
    font-weight: 700;
  }
}

section.login {
  .form-control {
    font-weight: 600;
    font-size: 1rem;

    &:focus {
      box-shadow: none;
    }
  }

}

.survey, .attendances, .checklist, .private {
  section {
    padding: 40px 0 80px 0;
  }

  h2, h3, h4 {
    color: $black;
  }

  h4 {
    font-size: 21px;
    font-weight: 700;
  }

  /*.form-control {
    border: $input-border-width solid $input-border-color;
  }*/

  .btn {
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
  }

  .btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  header .topbar .btn {
    font-size: 20px;
    line-height: 27px;
  }

  @include media-breakpoint-up(md) {
    .btn {
      font-size: 20px;
      line-height: 27px;
    }
    .btn-sm {
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }

  .header {
    background-color: #f8f8f8;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    h2 {
      margin-bottom: 0.25rem;
    }
  }

  ul.list-choose-block, ol.list-choose-block {
    margin: 1em 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0.5em;
      margin: 0;
      border-bottom: 1px solid #dddddd;

      &:hover {
        background-color: #f3f3f3;
      }
    }

    a {
      color: #444;
      text-decoration: none;
      display: block;
    }

    label {
      color: #444;
    }
  }

  ul.list-participants-check span {
    display: block;
    float: left;
    width: 30px;
    height: 30px;
  }

  ul.existing-users-list {
    margin: 1em 0;
    padding: 0;
    list-style: none;
  }

  ul.existing-users-list label {
    padding: 0.2em 0.5em 0.2em 1.8em;
    font-size: 1.0em;
  }

  ul.existing-users-list label:hover {
    background-color: #efefef;
  }

  ul.existing-users-list input[type="radio"] {
    position: absolute;
    margin: 0.3em;
  }

  ul.existing-users-list input[type="radio"]:checked + label {
    background-color: #f5e8e8;
  }

  .nav-link.active .nav-show-active {
    display: inline !important;
  }

  // autocomplete dropdown
  .ui-widget.ui-widget-content {
    border-color: #dddddd;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .ui-menu .ui-state-active, .ui-menu .ui-state-focus {
    margin: -1px 0;
  }

  .ui-menu .ui-menu-item {
    border-bottom: 1px solid #dddddd;
  }

  .ui-menu .ui-menu-item-wrapper {
    border-color: #dddddd;
    padding: 0.5rem;
  }

  .ui-menu .ui-menu-item-wrapper .name {
    font-size: 1rem;
    line-height: 1.5rem;
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ui-menu .ui-menu-item-wrapper .birth {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .existing-user {
    border: 1px solid $black;
    color: $black;
    padding: 0.5rem;

    .name {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .birth {
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
}

.survey textarea::placeholder {
  opacity: 0.6;
}
